@font-face {
    font-family: 'Field Gothic';
    font-style: normal;
    font-display: 'swap';
    src: url('FieldGothic-No-54.woff2') format('woff2'), /* Super Modern Browsers */
    url('FieldGothic-No-54.woff') format('woff'), /* Pretty Modern Browsers */
    url('FieldGothic-No-54.ttf')  format('truetype') /* Safari, Android, iOS */;
}


@font-face {
    font-family: 'Field Gothic W Light';
    font-style: normal;
    font-display: 'swap';
    src: url('FieldGothic-No-52.woff2') format('woff2'), /* Super Modern Browsers */
    url('FieldGothic-No-52.woff') format('woff'), /* Pretty Modern Browsers */
    url('FieldGothic-No-52.ttf')  format('truetype') /* Safari, Android, iOS */;
}

@font-face {
    font-family: 'Field Gothic W';
    font-style: normal;
    font-display: 'swap';
    src:  url('FieldGothic-No-46.woff2') format('woff2'), /* Super Modern Browsers */
    url('FieldGothic-No-46.woff') format('woff'), /* Pretty Modern Browsers */
    url('FieldGothic-No-46.ttf')  format('truetype') /* Safari, Android, iOS */; 
}

@font-face {
    font-family: 'Field Gothic Tight';
    font-style: normal;
    font-display: 'swap';
    src: url('WestinghouseSans-No-16.woff2') format('woff2'), /* Super Modern Browsers */
    url('WestinghouseSans-No-16.woff') format('woff'), /* Pretty Modern Browsers */
    url('WestinghouseSans-No-16.ttf')  format('truetype') /* Safari, Android, iOS */;
}

@font-face {
    font-family: 'Field Gothic Light';
    font-style: normal;
    font-display: 'swap';
    src:  url('WestinghouseSans-No-14.woff2') format('woff2'), /* Super Modern Browsers */
    url('WestinghouseSans-No-14.woff') format('woff'), /* Pretty Modern Browsers */
    url('WestinghouseSans-No-14.ttf')  format('truetype') /* Safari, Android, iOS */;
}

@font-face {
    font-family: 'Westinghouse';
    font-style: normal;
    font-display: 'swap';
    src: url('WestinghouseSans-No-34.woff2') format('woff2'), /* Super Modern Browsers */
    url('WestinghouseSans-No-34.woff') format('woff'), /* Pretty Modern Browsers */
    url('WestinghouseSans-No-34.ttf')  format('truetype') /* Safari, Android, iOS */;
}

@font-face {
    font-family: 'Field Gothic Condensed';
    font-style: normal;
    font-display: 'swap';
    src: url('FieldGothic-No.55.otf') format('opentype');
}
@font-face {
    font-family: 'Field Gothic Demi';
    font-style: normal;
    font-display: 'swap';
    src: url('FieldGothic-No.52.otf') format('opentype');
}
